import Vue from "vue";
import VeeValidate from "vee-validate";
import VueCookies from "vue-cookies";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import App from "./App.vue";

const sentryDSN = process.env.VUE_APP_SENTRY_DSN;
const sentryENV = process.env.NODE_ENV;

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
  ],
  environment: sentryENV,
  attachStacktrace: true,
  debug: sentryENV === "production" ? true : false,
});

// Fixes IE issue around objects and 'forEach'
if (typeof NodeList.prototype.forEach !== "function") {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

import "es6-promise/auto";
import store from "./store";
import router from "./router";

var JsDiff = require("diff");

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(VueCookies);

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

// Extend Vee-Validate to enforce strong passwords
VeeValidate.Validator.extend("verify_password_chars", {
  getMessage: () =>
    `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  validate: value => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[/[()[\\]{}|`~!@#$%^&*_\\-+=;:'\",<>./?)])(?=.{8,})",
    );
    return strongRegex.test(value);
  },
});

// Extend Vee-Validate to check if password contains first/last name
VeeValidate.Validator.extend(
  "verify_password_first",
  {
    // eslint-disable-next-line no-unused-vars
    getMessage: (field, params, data) => {
      return data.message;
    },
    validate: (value, [firstname]) => {
      const valueLowerCase = value.toLowerCase();
      const firstnameLowerCase = firstname.toLowerCase();
      var s = 0;
      JsDiff.diffChars(valueLowerCase, firstnameLowerCase).forEach(part => {
        var num = part.added ? 0 : part.removed ? 0 : part.count;
        s = num > s ? num : s;
      });
      return {
        valid: firstnameLowerCase.length > 2 ? s < 3 : true,
        data:
          s < 3
            ? undefined
            : {
                message:
                  "The password may not contain three or more consecutive characters from your first name",
              },
      };
    },
  },
  {
    hasTarget: true,
  },
);

VeeValidate.Validator.extend(
  "verify_password_last",
  {
    // eslint-disable-next-line no-unused-vars
    getMessage: (field, params, data) => {
      return data.message;
    },
    validate: (value, [lastname]) => {
      var s = 0;
      const valueLowerCase = value.toLowerCase();
      const lastnameLowerCase = lastname.toLowerCase();
      JsDiff.diffChars(valueLowerCase, lastnameLowerCase).forEach(part => {
        var num = part.added ? 0 : part.removed ? 0 : part.count;
        s = num > s ? num : s;
      });
      return {
        valid: lastnameLowerCase.length > 2 ? s < 3 : true,
        data:
          s < 3
            ? undefined
            : {
                message:
                  "The password may not contain three or more consecutive characters from your last name",
              },
      };
    },
  },
  {
    hasTarget: true,
  },
);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
