import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

Vue.use(VueRouter);

const accountLookup = "Account Lookup";

const routes = [
  {
    path: "/",
    name: accountLookup,
    component: () =>
      import(/* webpackChunkName: "account-lookup" */ "../views/AccountLookup/index"),
  },
  {
    path: "/create-an-account",
    name: "Create an Account",
    component: () =>
      import(/* webpackChunkName: "create-an-account" */ "../views/CreateAnAccount/index"),
    beforeEnter: (to, from, next) => {
      if (Object.keys(store.state.userDataObject).length) {
        if (
          store.state.salesforceInitialCompanyType === "vendor" ||
          !store.state.salesforceInitialCompanyType
        ) {
          document.title = "Apply for a CableLabs Vendor Account";
        }
        next();
      } else {
        next({
          name: accountLookup,
        });
      }
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error/index"),
    beforeEnter(to, from, next) {
      if (store.state.errorOccurred) {
        store.commit("updateErrorOccurred", false);
        next();
      } else {
        next({
          name: accountLookup,
        });
      }
    },
  },
  {
    path: "/styleguide",
    name: "Styleguide",
    component: () =>
      import(/* webpackChunkName: "styleguide" */ "../views/Styleguide/index"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
