<template>
  <div id="app">
    <transition name="fade">
      <Spinner v-if="loading" />
    </transition>
    <div v-cloak>
      <div class="background-gradient" />
      <div
        ref="page"
        class="page"
        :class="pageClass"
      >
        <Header />
        <main
          class="main-content"
          role="main"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <router-view />
          </transition>
          <CookieConsent />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import CookieConsent from "@/components/CookieConsent";
import Spinner from "@/components/PageLoader";

export default {
  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    this.loading = false;

    this.$router.onReady(() => {
      this.$refs.page.className = `page page--${this.$route.name
        .replace(/\s+/g, "-")
        .toLowerCase()}`;
    });
  },

  components: {
    Header,
    CookieConsent,
    Spinner,
  },

  computed: {
    pageClass() {
      if (this.$route.name) {
        const routeName = this.$route.name;
        const routeNameClass = routeName.replace(/\s+/g, "-").toLowerCase();

        return `page--${routeNameClass}`;
      } else return "";
    },
  },
};
</script>

<style lang="scss">
@import "./scss/layout";
@import "./scss/typography";
@import "./scss/components";
@import "./scss/animations";
@import "./scss/vendor";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $color__grey--lt;
  font-family: $font-standard;
  color: $color__black;
  position: relative;
}

[v-cloak] {
  display: none;
}
</style>
