import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import RegisterService from "@/services/RegisterService";
import ProjectsService from "@/services/ProjectsService";

import { getField, updateField } from "vuex-map-fields";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

function initialState() {
  return {
    email: "",
    userInProgress: false,
    userHasAccount: false,
    userHasAccountOtherEmail: false,
    newsletterToggle: true,
    password: "",
    salesforceInitialCompanyType: "",
    salesforceCompanyName: "",
    salesforceCompanyId: "",
    salesforceCompanyAddress: "",
    salesforceCompanyWebsite: "",
    salesforceCablelabsLogin: "",
    salesforceCompanyRegion: "",
    salesforceDupeName: false,
    adDupeName: false,
    salesforceContactAccountId: "",
    domainBlacklisted: false,
    emailBlacklisted: false,
    currentTab: "My Info",
    tabs: [],
    userDataObject: {},
    firstName: "",
    lastName: "",
    username: "",
    contractorToggle: false,
    managerFirstName: "",
    managerLastName: "",
    managerEmail: "",
    managerDomainBlacklisted: false,
    managerInSalesforce: null,
    managerDomainMatches: null,
    areasOfInterest: [
      {
        area: "AI & Machine Learning",
        checked: false,
      },
      {
        area: "Security",
        checked: false,
      },
      {
        area: "Technology Policy",
        checked: false,
      },
      {
        area: "Technology Strategy",
        checked: false,
      },
      {
        area: "Wired",
        checked: false,
      },
      {
        area: "Wireless",
        checked: false,
      },
    ],
    selectedAreasOfInterest: [],
    companyName: "",
    companyId: "",
    companyWebsite: "",
    companyAddressLine1: "",
    companyAddressLine2: "",
    companyCity: "",
    companyRegion: "",
    companyRegionName: "",
    companyZip: "",
    companyCountry: "",
    companyCountryName: "",
    companyBusiness: "",
    department: "",
    jobTitle: "",
    referral: "",
    phoneNumber: "",
    newCompanyAddressToggle: false,
    workingCompanyAddressLine1: "",
    workingCompanyAddressLine2: "",
    workingCompanyCity: "",
    workingCompanyRegion: "",
    workingCompanyZip: "",
    workingCompanyCountry: "",
    workingCompanyRegionName: "",
    workingCompanyCountryName: "",
    techOfInterest: [
      {
        tech: "DOCSIS",
        checked: false,
      },
      {
        tech: "Wireless",
        checked: false,
      },
      {
        tech: "SDN/NFV",
        checked: false,
      },
      {
        tech: "Internet of Things",
        checked: false,
      },
      {
        tech: "Optical",
        checked: false,
      },
    ],
    selectedTechOfInterest: [],
    projects: [],
    selectedProjects: [],
    signupEmailsSent: false,
    token: null,
    userRegistrationComplete: false,
    errorOccurred: false,
    userNeedsToResetPassword: false,
    showWelcomeBackBanner: false,
  };
}

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: initialState,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    resetStore(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        if (key !== "email" && key !== "newsletterToggle") {
          state[key] = s[key];
        }
      });
    },
    initializeTabs(state, data) {
      state.tabs = data;
    },
    updateDomainBlacklistValue(state, data) {
      state.domainBlacklisted = !!data;
    },
    updateEmailBlacklistValue(state, data) {
      state.emailBlacklisted = !!data;
    },
    updateManagerDomainBlacklistValue(state, data) {
      state.managerDomainBlacklisted = !!data;
    },
    updateEmail(state, value) {
      state.email = value;
    },
    updateUserAccountStatus(state, value) {
      state.userHasAccount = value;
    },
    updateUserAccountOtherEmailStatus(state, value) {
      state.userHasAccountOtherEmail = value;
    },
    updateNewsletterToggle(state, value) {
      state.newsletterToggle = value;
    },
    updateTabs(state, index) {
      state.tabs.splice(index, 1);
    },
    updateCurrentTab(state, value) {
      state.currentTab = value;
    },
    updateProjects(state, data) {
      state.projects = data;
    },
    updateToken(state, value) {
      state.token = value;
    },
    updateFirstName(state, value) {
      state.firstName = value;
    },
    updateLastName(state, value) {
      state.lastName = value;
    },
    updateUsername(state, value) {
      state.username = value;
    },
    updateContractorToggle(state, value) {
      state.contractorToggle = value;
    },
    updateManagerDomainMatches(state, value) {
      state.managerDomainMatches = value;
    },
    updateManagerInSalesforce(state, value) {
      state.managerInSalesforce = value;
    },
    updateManagerFirstName(state, value) {
      state.managerFirstName = value;
    },
    updateManagerLastName(state, value) {
      state.managerLastName = value;
    },
    updateManagerEmail(state, value) {
      state.managerEmail = value;
    },
    updateSalesforceCablelabsLogin(state, value) {
      state.salesforceCablelabsLogin = value;
    },
    userRegistrationStatus(state, value) {
      state.userInProgress = value;
    },
    updateUserRegistrationComplete(state, value) {
      state.userRegistrationComplete = value;
    },
    updateUserDataObject(state, value) {
      state.userDataObject = value;
    },
    updateCompanyName(state, value) {
      state.companyName = value;
    },
    updateCompanyWebsite(state, value) {
      state.companyWebsite = value;
    },
    updateCompanyBusiness(state, value) {
      state.companyBusiness = value;
    },
    updateCompanyAddressLine1(state, value) {
      state.companyAddressLine1 = value;
    },
    updateCompanyAddressLine2(state, value) {
      state.companyAddressLine2 = value;
    },
    updateCompanyCountry(state, value) {
      state.companyCountry = value;
    },
    updateCompanyRegion(state, value) {
      state.companyRegion = value;
    },
    updateCompanyCity(state, value) {
      state.companyCity = value;
    },
    updateCompanyZip(state, value) {
      state.companyZip = value;
    },
    updateDepartment(state, value) {
      state.department = value;
    },
    updateJobTitle(state, value) {
      state.jobTitle = value;
    },
    updatePhoneNumber(state, value) {
      state.phoneNumber = value;
    },
    updateDifferentAddressToggle(state, value) {
      state.newCompanyAddressToggle = value;
    },
    updateWorkingCompanyAddressLine1(state, value) {
      state.workingCompanyAddressLine1 = value;
    },
    updateWorkingCompanyAddressLine2(state, value) {
      state.workingCompanyAddressLine2 = value;
    },
    updateWorkingCompanyCountry(state, value) {
      state.workingCompanyCountry = value;
    },
    updateWorkingCompanyRegion(state, value) {
      state.workingCompanyRegion = value;
    },
    updateWorkingCompanyCity(state, value) {
      state.workingCompanyCity = value;
    },
    updateWorkingCompanyZip(state, value) {
      state.workingCompanyZip = value;
    },
    updateReferral(state, value) {
      state.referral = value;
    },
    updateToggleArrayValues(state, data) {
      state.data = data;
    },
    addItemToToggleArray(state, object) {
      object.selectedItemsArray.push(object.itemName);
    },
    removeItemFromToggleArray(state, object) {
      object.selectedItemsArray.splice(
        object.selectedItemsArray.indexOf(object.itemName),
        1,
      );
    },
    updateProjectToggleArrayValues(state, data) {
      state.selectedProjects = data;
    },
    updateAreaToggleArrayValues(state, data) {
      state.selectedAreasOfInterest = data;
    },
    updateErrorOccurred(state, value) {
      state.errorOccurred = value;
    },
  },
  actions: {
    updateField,
    async domainBlacklistLookup({ commit }, email) {
      const emailDomain = email.replace(/.*@/, "");

      await RegisterService.domainBlacklistLookup(emailDomain).then(response => {
        commit("updateDomainBlacklistValue", response.data[0]);
      });
    },
    async emailBlacklistLookup({ commit }, email) {
      const emailStart = email.substring(0, email.lastIndexOf("@"));

      await RegisterService.emailBlacklistLookup(`${emailStart}@`).then(response => {
        commit("updateEmailBlacklistValue", response.data[0]);
      });
    },
    async managerDomainBlacklistLookup({ commit }, email) {
      const emailDomain = email.replace(/.*@/, "");

      await RegisterService.domainBlacklistLookup(emailDomain).then(response => {
        commit("updateManagerDomainBlacklistValue", response.data[0]);
      });
    },
    async projectLookup({ commit }, companyInfo) {
      if (companyInfo.companyType === "vendor" && companyInfo.companyId) {
        await ProjectsService.vendorProjectLookup(companyInfo.companyId).then(
          response => {
            if (response.data) {
              let projectsArray = response.data.map(v => {
                v.checked = false;
                return v;
              });

              commit("updateProjects", projectsArray);
            }
          },
        );
      } else if ((companyInfo.companyType === "member" || companyInfo.companyType === "international")
        && this.state.contractorToggle) {
        await ProjectsService.memberProjectLookup().then(
          response => {
            if (response.data) {
              let projectsArray = response.data.map(v => {
                v.checked = false;
                return v;
              });

              commit("updateProjects", projectsArray);
            }
          },
        );
      }
    },
    async updateTabs({ commit }) {
      const companyType = this.state.salesforceInitialCompanyType;
      const companyName = this.state.salesforceCompanyName;
      const companyHasProjects = this.state.projects.length > 0;

      // Hide the Agreement tab for existing vendor companies
      if (companyType === "vendor" && companyName && companyHasProjects) {
        commit("updateTabs", 4);
      // Hide the Join Projects and Agreement tabs for member companies
      } else if ((companyType === "member" || companyType === "international") && !this.state.contractorToggle) {
        commit("updateTabs", 4);
        commit("updateTabs", 3);
      // Hide the Agreement tab if user is a contractor for a member company
      } else if (this.state.contractorToggle) {
        commit("updateTabs", 4);
      // Hide the Join Projects tab for new vendor companies
      } else {
        commit("updateTabs", 3);
      }

      if (this.state.userInProgress && this.state.userDataObject['currentStep'] !== 0) {
        const tabsArray = this.state.tabs;
        const nextTabNumber =
          tabsArray.map(step => step.name).indexOf(this.state.currentTab) + 1;
        const nextTabKey = Object.keys(tabsArray)[nextTabNumber];
        const nextTabObject = tabsArray[nextTabKey];

        commit("updateCurrentTab", nextTabObject.name);
      }
    },
    async databaseLookup({ commit }, email) {
      await RegisterService.databaseEmailLookup(email).then(response => {
        if (response.data.length > 0 && response.data[0].currentStep < 6) {
          const databaseRecord = response.data[0];
          if (databaseRecord.currentStep !== 0) {
            const currentTabNumber = this.state.tabs.find(
              tab => tab.step === databaseRecord.currentStep,
            );
            const currentTab = currentTabNumber.name;
            commit("updateCurrentTab", currentTab);
          } else {
            commit("updateCurrentTab", "My Info");
          }

          commit("userRegistrationStatus", true);
          commit("updateUserDataObject", databaseRecord);
          commit("updateFirstName", databaseRecord.firstName);
          commit("updateLastName", databaseRecord.lastName);

          if (databaseRecord.areasOfInterest) {
            const selectedAreasArray = databaseRecord.areasOfInterest.split(
              ";",
            );

            commit("updateAreaToggleArrayValues", selectedAreasArray);
          }

          if (databaseRecord.managerEmail) {
            commit("updateManagerEmail", databaseRecord.managerEmail);
            commit("updateManagerFirstName", databaseRecord.managerFirstName);
            commit("updateManagerLastName", databaseRecord.managerLastName);
            commit("updateManagerDomainMatches", databaseRecord.managerDomainMatches);
            commit("updateManagerInSalesforce", databaseRecord.managerInSalesforce);
            commit("updateContractorToggle", true);
          }

          commit("updateUsername", databaseRecord.clLoginId);

          commit("updateCompanyName", databaseRecord.company);
          commit("updateCompanyWebsite", databaseRecord.companyWebsite);
          commit("updateCompanyAddressLine1", databaseRecord.compAddressLine1);
          commit("updateCompanyAddressLine2", databaseRecord.compAddressLine2);
          commit("updateCompanyCountry", databaseRecord.compCountryId);
          commit("updateCompanyBusiness", databaseRecord.compBusiness);

          if (databaseRecord.compStateId) {
            commit(
              "updateCompanyRegion",
              JSON.stringify(databaseRecord.compStateId),
            );
          }
          commit("updateCompanyCity", databaseRecord.compCity);
          commit("updateCompanyZip", databaseRecord.compZip);
          commit("updateDepartment", databaseRecord.department);
          commit("updateJobTitle", databaseRecord.jobTitle);
          commit("updatePhoneNumber", databaseRecord.phone);

          if (databaseRecord.addressLine1) {
            commit("updateDifferentAddressToggle", true);
          }
          commit(
            "updateWorkingCompanyAddressLine1",
            databaseRecord.addressLine1,
          );
          commit(
            "updateWorkingCompanyAddressLine2",
            databaseRecord.addressLine2,
          );
          commit("updateWorkingCompanyCountry", databaseRecord.countryId);

          if (databaseRecord.stateId) {
            commit(
              "updateWorkingCompanyRegion",
              JSON.stringify(databaseRecord.stateId),
            );
          }
          commit("updateWorkingCompanyCity", databaseRecord.city);
          commit("updateWorkingCompanyZip", databaseRecord.zip);
          commit("updateReferral", databaseRecord.howDidYouHear);

          if (databaseRecord.projectsOfInterest) {
            const selectedProjectsArray = databaseRecord.projectsOfInterest.split(
              ";",
            );

            commit("updateProjectToggleArrayValues", selectedProjectsArray);
          }
        } else if (
          response.data.length > 0 &&
          response.data[0].currentStep === 99
        ) {
          commit("updateUserAccountStatus", true);
        } else {
          commit("userRegistrationStatus", false);
        }
      });
    },
  },
  modules: {},
});
