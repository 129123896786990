import RegisterApi from "@/services/RegisterApi";

export default {
  adNameLookup(firstName, lastName) {
    return RegisterApi.get(
      `/user?filter[where][and][0][givenName]=${firstName}&filter[where][and][1][sn]=${lastName}`,
    );
  },
  adEmailLookup(email) {
    return RegisterApi.get(`/user?filter[where][mail]=${email}`);
  },
  deleteUserFromAd(username) {
    return RegisterApi.delete(`/user/${username}`);
  },
  domainBlacklistLookup(emailDomain) {
    return RegisterApi.get(`/blacklist/domain?filter[where][domain]=${emailDomain}`);
  },
  databaseEmailLookup(email) {
    return RegisterApi.get(`/registration?filter[where][email]=${email}`);
  },
  emailBlacklistLookup(emailStart) {
    return RegisterApi.get(`/blacklist/email?filter[where][emailStart]=${emailStart}`);
  },
  registerUserData(registrantData) {
    return RegisterApi.post("/registration", registrantData);
  },
  updateUserData(userId, registrantData) {
    Object.keys(registrantData).forEach(key => {
      if (
        !registrantData[key] &&
        registrantData[key] !== false &&
        key !== "currentStep"
      ) {
        delete registrantData[key];
      }
    });
    return RegisterApi.put(`/registration/${userId}`, registrantData);
  },
  async createUser(userId, password) {
    const created = await RegisterApi.post(`/create/${userId}`);
    await RegisterApi.patch(`/password/${created.data.sAMAccountName}`, {
      sAMAccountName: created.data.sAMAccountName,
      unicodePwd: password,
    });
    return created.data.sAMAccountName;
  },
};
