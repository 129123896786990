import RegisterApi from "@/services/RegisterApi";

export default {
  vendorProjectLookup(companyId) {
    return RegisterApi.get(
      `/vendor-project/${companyId}?filter[where][isActive]=true`,
    );
  },
  memberProjectLookup() {
    return RegisterApi.get("/member-project?filter[where][isActive]=true");
  },
};
