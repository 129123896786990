import axios from "@hokify/axios";
import axiosRetry from "axios-retry";
import { errorNotifier } from "./interceptors";

const ServerApi = axios.create({
  baseURL: process.env.VUE_APP_SERVER_BASE,
  withCredentials: true,
  crossDomain: true,
  timeout: 15000,
});

axiosRetry(ServerApi, { retryDelay: axiosRetry.exponentialDelay, retries: 2 });

ServerApi.interceptors.response.use(
  response => response,
  error => {
    errorNotifier(error);
  },
);

export default ServerApi;
