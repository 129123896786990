<template>
  <header class="header">
    <div class="content content--standard-width flex flex--space-between">
      <div
        v-if="showWelcomeBackBanner"
        class="notification notification--blue"
      >
        <span class="notification__text">
          Welcome back! Continue where you left off.
        </span>
        <span
          class="notification__close"
          @click="closeNotification"
        >
          <img
            src="@/assets/close-blue.svg"
            alt="Close Icon"
          >
        </span>
      </div>
      <a
        href="https://www.cablelabs.com"
        target="_blank"
        title="CableLabs Website"
        class="header__cablelabs-logo"
      >
        <img src="@/assets/cablelabs-logo.svg">
      </a>
      <p class="content__text content__text--white">
        <span class="header__content header__content--login">
          Already have an account?
          <a
            href="https://account.cablelabs.com/sign-in"
            class="content__link"
          >
            Sign In
          </a>
        </span>
        <a
          href="https://www.cablelabs.com/faqs"
          target="_blank"
          title="CableLabs FAQs"
          class="header__question-mark"
        ><img
          src="@/assets/question-mark-red.svg"
        ></a>
      </p>
    </div>
  </header>
</template>

<script>
import Notification from "@/mixins/Notification";
import {mapFields} from "vuex-map-fields";

export default {
  name: "Header",

  mixins: [Notification],

  computed: {
    ...mapFields(["showWelcomeBackBanner"]),
  },

  watch:{
    $route (to) {
      this.showWelcomeBackBanner = to.name === "Create an Account" && this.$store.state.userInProgress;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~sass-rem";

.header {
  padding: rem(42px) 0;
  overflow: hidden;

  @include breakpoint(break-2) {
    padding: rem(65px) 0 rem(62px);
  }

  .notification {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  &__cablelabs-logo {
    img {
      max-width: rem(110px);

      @include breakpoint(break-1) {
        max-width: unset;
      }
    }
  }

  &__content--login {
    display: none;

    .content__link {
      margin: 0 rem(33px) 0 rem(13px);
    }
  }

  .content__text--white {
    font-size: rem(18px);
    line-height: rem(24px);
  }

  &__question-mark {
    display: inline-block;
    vertical-align: top;
    height: rem(20px);
    width: rem(20px);

    img {
      transition: all 0.2s ease-in-out;
      margin-top: rem(-22px);
      margin-left: rem(-24px);

      &:hover,
      &:focus {
        transform: scale(1.2);
      }
    }
  }
}

.page {
  &--create-an-account {
    .header {
      @include breakpoint(break-2) {
        &__content--login {
          display: inline-block;
        }
      }
    }
  }
}
</style>
