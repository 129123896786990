import router from "@/router";
import store from "@/store";
import EmailService from "@/services/EmailService";

export default async function(error) {
  // ignore login errors as the account won't always be created in time due to ldap latency
  // and ignore errors around vendor accounts not being found to pull in projects
  if (
    error.code === "ECONNABORTED" ||
    (error.response &&
      (!error.response.request.responseURL.includes("/login") ||
        error.response.data.error.code !== "ENTITY_NOT_FOUND"))
  ) {
    await store.commit("updateErrorOccurred", true);
    router.push("/error");

    const data = error.response ? error.response.data : error.stack;
    const status = error.response ? error.response.status : error.code;
    const statusText = error.response
      ? error.response.statusText
      : error.message;
    const responseUrl = error.response ? error.response.responseUrl : "";
    const configData = error.response ? error.response.configData : "";
    const userData = error.response ? error.response.userDataObject : "";
    const userEmail = !error.response.userDataObject ? store.state.email : "";

    EmailService.sendErrorEmail({
      data,
      status,
      statusText,
      responseUrl,
      configData,
      userData,
      userEmail,
    });
  }
  return Promise.reject(error);
}
