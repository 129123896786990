import ServerApi from "@/services/ServerApi";

export default {
  sendEmailCode(email) {
    return ServerApi.post("/email-validation", email);
  },
  checkEmailCode(validationCode) {
    return ServerApi.post("/check-email-validation", validationCode);
  },
  sendSignupEmailsMember(data) {
    return ServerApi.post("/send-signup-emails-member", data);
  },
  sendSignupEmailsMemberContractor(data) {
    return ServerApi.post("/send-signup-emails-member-contractor", data);
  },
  sendSignupEmailsVendorExistingCompany(data) {
    return ServerApi.post("/send-signup-emails-vendor-existing-company", data);
  },
  sendSignupEmailsVendorCompanyNoNda(data) {
    return ServerApi.post("/send-signup-emails-vendor-company-no-nda", data);
  },
  sendSignupEmailsVendorNewCompany(data) {
    return ServerApi.post("/send-signup-emails-vendor-new-company", data);
  },
  sendSignupEmailToIntlAdmin(data) {
    return ServerApi.post("/send-signup-email-intl-admin", data);
  },
  sendTechAgreementEmailsVendor(data) {
    return ServerApi.post("/send-agreement-emails-vendor", data);
  },
  sendAdDupeNameEmail(data) {
    return ServerApi.post("/send-ad-dupe-name-email", data);
  },
  sendSalesforceEmailLoginExistsEmail(data) {
    return ServerApi.post("/send-salesforce-email-login-exists-email", data);
  },
  sendSalesforceNameLoginExistsEmail(data) {
    return ServerApi.post("/send-salesforce-name-login-exists-email", data);
  },
  sendErrorEmail(error) {
    return ServerApi.post("/send-error-email", error);
  },
};
