import axios from "@hokify/axios";
import axiosRetry from "axios-retry";
import { errorNotifier } from "./interceptors";

const RegisterApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  withCredentials: true,
  crossDomain: true,
  timeout: 15000,
});

axiosRetry(RegisterApi, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 2,
});

RegisterApi.interceptors.response.use(
  response => response,
  error => {
    errorNotifier(error);
  },
);

export default RegisterApi;
