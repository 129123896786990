<template>
  <div class="spinner">
    <div class="spinner__wrapper">
      <div class="spinner__interior" />
    </div>
  </div>
</template>

<script>
import Velocity from "velocity-animate/velocity.ui.min.js";
export default {
  props: {
    active: {
      type: Boolean,
    },
  },
  created() {
    // Watch the active property
    this.$watch("active", (newVal, oldVal) => {
      // If the value it's going to doesn't match its current value
      if (newVal !== oldVal) {
        // Handle the change to the new value
        this.handleActiveChange(newVal);
      }
    });
  },
  methods: {
    handleActiveChange(bool) {
      // If bool is true, we're going from 0 to 1 opacity else
      // vice-versa
      const opacityChange = bool ? [1, 0] : [0, 1];
      // Run the opacity change on the element
      Velocity(this.$el, {
        opacity: opacityChange,
      });
    },
  },
};
</script>

<style lang="scss">
.spinner {
  height: 100vh;
  width: 100vw;
  display: block;
  position: absolute;
  background-color: #f3f3f3;
  z-index: 20;

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateZ(0) translate(-50%, -50%);
  }

  &__interior {
    display: block;
    width: 10rem;
    height: 10rem;
    border: 0.1rem solid rgba($color__red, 0.3);
    border-radius: 5rem;
    border-top-color: $color__red;
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
